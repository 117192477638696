<template>
  <div>
    <div class="row align-content-center">
      <div class="col-9">
        <h5 class="pl-1 font-weight-bold my-2">Assessment Report</h5>
      </div>
    </div>

    <table class="table table-sm table-bordered small">
      <thead>
      <tr>
        <th class="align-middle text-center"  style="width: 7%">Code</th>
        <th class="align-middle text-center">Name</th>
        <th class="align-middle text-center">Conduct Date</th>
        <th class="align-middle text-center">Place</th>
        <th class="align-middle text-center">Vessel</th>
        <th class="align-middle text-center">Action</th>
      </tr>
      </thead>
      <tbody>
        <template v-if="crewDocuments">
            <tr v-for="(document,index) in crewDocuments" :key="index">
              <td>{{document.documentCode ? document.documentCode.toUpperCase() : ''}}</td>
              <td>{{document.documentName ? document.documentName.toUpperCase() : ''}}</td>
              <td>{{formatEliteDate(document.conduct_date)}}</td>
              <td>{{document.place ? document.place.toUpperCase() : ''}}</td>
              <td>{{document.vessel ? document.vessel.toUpperCase() : ''}}</td>
              <td class="text-center">
<!--                -->
                <button type="button"
                        :disabled="!document.id" style="cursor: pointer"
                        class="btn btn-secondary btn-xs"
                        data-toggle="modal"
                        data-target="#crewDocument"
                        @click="viewCrewDocument(document)">
                  View
                </button>
              </td>
            </tr>
        </template>
      </tbody>
    </table>
    <div class="modal fade"  data-backdrop="static" data-keyboard="false" id="crewDocument" tabindex="-1" aria-labelledby="crewDocument" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content" style="height:auto">
          <div class="modal-header">
            <h5 class="modal-title" id="crewIncidentBehaviorTitle">VIEW <strong>{{documentName}}</strong></h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-4">
                <table class="w-100">
                  <tr>
                    <td>Conduct Date:</td>
                    <td class="font-weight-bold">{{crewDocument ? formatEliteDate(crewDocument.conduct_date) : ''}}</td>
                  </tr>
                  <tr>
                    <td>Place:</td>
                    <td class="font-weight-bold">{{crewDocument.place ? crewDocument.place.toUpperCase() : ''}}</td>
                  </tr>
                  <tr>
                    <td>Vessel:</td>
                    <td class="font-weight-bold">{{crewDocument.vessel ? crewDocument.vessel.toUpperCase() : ''}}</td>
                  </tr>
                </table>
                <hr>
                <h6 class="font-weight-bold" id="crewIncidentBehaviorTitle">Document History</h6>
                <table class="table table-sm table-bordered small">
                  <thead>
                  <tr>
                    <td class="align-middle text-center font-weight-bold">CONDUCT DATE</td>
                    <td class="align-middle text-center font-weight-bold">PLACE</td>
                    <td class="align-middle text-center font-weight-bold">VESSEL</td>
                  </tr>
                  </thead>
                  <tbody>
<!--                  @click="getSelected(history)"-->
                  <template v-if="crewDocumentHistory">
                    <tr v-for="history in crewDocumentHistory"  @click="getSelected(history)" :class="{
                        'selected' : crewDocument.id === history.id
                      }"  style="cursor: pointer">
                      <td class="text-center font-weight-bold" :class="{'text-white' : crewDocument.id === history.id }">{{history.conduct_date ? formatEliteDate(history.conduct_date) : 'N/A' }}</td>
                      <td class="text-center font-weight-bold" :class="{'text-white' : crewDocument.id === history.id }">{{history.place ? history.place.toUpperCase() : 'N/A' }}</td>
                      <td class="text-center font-weight-bold" :class="{'text-white' : crewDocument.id === history.id }">{{history.vessel ? history.vessel.toUpperCase() : 'N/A' }}</td>
                    </tr>
                  </template>

                  </tbody>
                </table>
              </div>
              <div class="col-8" style="height: 77vh">
                <template v-if="crewDocumentFile">
                  <iframe style="height: inherit; width: 100%" :src="crewDocumentFile"></iframe>
                </template>
                <div v-else class="alert alert-danger">
                  NO FILE FOUND
                </div>

              </div>
            </div>

          </div>
<!--          @click="resetCrewDocument"-->
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" >Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {DateService} from "@/services/DateService";
import {CrewService} from "@/services/CrewService";
import {FileService} from "@/services/FileService";

export default {
  name: "AssessmentReport",
  data(){
    return {
      crewDocument:{},
      documentName:'',
      crewDocumentId:null,
      crewDocumentHistory:{},
      crewDocumentFile:false,
    }
  },
  methods:{
    ...mapActions([
      'getAssessmentReport'
    ]),
    formatEliteDate(date){
      return DateService.dateEliteFormat(date);
    },
    async viewCrewDocument(assessment){
      this.crewDocument=Object.assign({},assessment);
      this.documentName=assessment.documentName ? assessment.documentName.toUpperCase() : ''
      const crewDocumentHistory= await CrewService.getCrewAssessmentHistoryFromCms({document:assessment.documentId,cms_id:this.$route.params.id});
      const crewDocumentFile= await CrewService.getCrewAssessmentFileFromCms({crewAssessmentFile: assessment.id});
      this.crewDocumentFile=crewDocumentFile ? FileService.base64FileToBlobUrl(crewDocumentFile.file) : false;
      this.crewDocumentHistory= Object.assign({},crewDocumentHistory);
    },
    async getSelected(assessment){
      this.crewDocument=Object.assign({},assessment);
      const crewDocumentFile= await CrewService.getCrewAssessmentFileFromCms({crewAssessmentFile: assessment.id});
      this.crewDocumentFile=crewDocumentFile ? FileService.base64FileToBlobUrl(crewDocumentFile.file) : false;
    },
    //
    // resetCrewDocument(){
    //   this.crewDocument={}
    //   this.documentName=''
    //   this.crewDocumentId=null
    //   this.crewDocumentHistory={}
    //   this.crewDocumentFile=false
    // }
  },
  computed:{
    ...mapGetters([
      'crewDocuments',
    ])
  },

  async created() {
    const params= {
      cms_id: this.$route.params.id,
    }
    await this.getAssessmentReport(params);
  },

}
</script>

<style scoped>
.selected{
  background-color: #e87c87 !important;
  color: white !important;
}

tr.bg-danger td {
  color: white !important;
}

tr.bg-danger td span {
  color: white !important;
}
</style>
